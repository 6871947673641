<div class="flex flex-col rounded-xl border border-[#EEE]">
  @for (player of state.joinedPlayers; track player.user_id; let index = $index) {
    <div class="flex flex-col gap-2 px-2 py-3 border-b border-[#EEE] last:border-none last:rounded-b-xl first:rounded-t-xl">
      <div class="flex w-full gap-2 items-center pr-[10px]">
        <span class="w-5 h-5 flex items-center justify-center">{{index + 1}}</span>
        <avatar [src]="player.user?.avatar" [alt]="player.user?.full_name"></avatar>
        <span class="flex-1 text-sm font-semibold text-[#1C1919]">{{player.user ? player.user.full_name : player.user_id}}</span>
        <span class="text-sm font-semibold text-[#1C1919]">{{state.statisticsPoints[player.id]}}</span>
      </div>
      <div class="flex gap-1 w-full">
        @for (question of state.questions; track question.id) {
          @if (state.statisticsAnswers) {
            <div
              class="flex-1 h-1 rounded-full bg-[#F3F3F3]"
              [class]="{
                'bg-red-500': state.statisticsAnswers[player.id] && state.statisticsAnswers[player.id][question.id] === false,
                'bg-green-500': state.statisticsAnswers[player.id] && state.statisticsAnswers[player.id][question.id] === true,
              }"
            ></div>
          }
        }
      </div>
    </div>
  } @empty {
    <div class="flex flex-col items-center justify-center gap-2 px-2 py-3 border-b border-[#EEE] last:border-none last:rounded-b-xl first:rounded-t-xl">
      Пусто
    </div>
  }
</div>
