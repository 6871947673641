import {Component, Input} from '@angular/core'
import {CommonModule} from '@angular/common'
import {ImageFallbackDirective} from "@shared/directives/img-fallback.directive"

@Component({
  standalone: true,
  imports: [CommonModule, ImageFallbackDirective],
  selector: 'avatar',
  styles: ':host {}',
  template: `
	  <img
		  fallback="{{gender ? 'assets/img/avatar.jpeg' : 'assets/img/avatar.jpeg'}}"
		  class="border-2 border-[#CBE1FF] rounded-full {{class}}"
		  [style.width.px]="width"
		  [style.height.px]="height"
		  [src]="src"
	  />
  `
})
export class AvatarComponent {
  @Input() src: string = ''
  @Input() class: string | Object = {}
  @Input() alt: string | number = ''
  @Input() width: number = 32
  @Input() height: number = 32
  @Input() gender: number
}
