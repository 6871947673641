<div class="page">

	<header-teacher/>

	<div insets [paddingBottom]="true" class="page-body w-full max-w-xl mx-auto">

		<div class="flex flex-col gap-3 p-4">

			<div class="flex gap-3 flex-col w-full mx-auto p-3 bg-[#EFEFFF] rounded-xl">

				<div class="flex gap-2 items-center">
					<select
						[(ngModel)]="mode"
						id="countries"
						class="h-12 border border-gray-300 text-gray-600 text-base rounded-lg block w-full py-2.5 px-4 focus:outline-none"
					>
						<option [value]="0">Синхронный квиз</option>
						<!--<option [value]="1">Индвидуальный квиз</option>-->
					</select>
					<mat-icon class="cursor-pointer">info</mat-icon>
				</div>

				<button
					(click)="onStart()"
					class="w-full flex gap-1 items-center justify-center text-center bg-primary-700 hover:bg-primary-700 disabled:opacity-70 text-white py-3 px-4 rounded-xl text-base transition-all max-w-xl mx-auto"
				>
					Начать квиз
				</button>

			</div>

			<div class="text-[15px] font-semibold">Готово ({{ readyCnt }})</div>

			@for (player of players; track player.user_id; let index = $index) {

				<div class="flex flex-col gap-3">

					<div
						class="flex items-center gap-2 px-2 py-[10px] rounded-[10px] border border-[#EEE] bg-[#F3F8FF]"
						[class]="{
		          'bg-white': player.status === 2 || player.status === 3,
		          'bg-[#4CCE170F]': player.online,
		          'bg-[#F3F8FF]': !player.online
		        }"
					>

						<span class="w-5 h-5 flex items-center justify-center text-xs text-[#7A7A7A]">{{ index + 1 }}</span>

						<div class="flex flex-1 border-r border-[#EBEBEB] gap-2 items-center">

							<avatar [class]="{'opacity-50': player.status === 2}" [src]="player.user?.avatar" [alt]="player.user?.full_name"></avatar>

							<div class="flex flex-col gap-[2px]">
								<span class="text-[#05044B] font-semibold text-[13px]">{{ player.user ? player.user.full_name : player.user_id }}</span>

								@if (player.status === 2) {
									<div class="flex flex-row gap-1 items-center">
										<span class="w-2 h-2 rounded-full bg-[#B5B5B5]"></span>
										<span class="text-sm text-[#B5B5B5]">Отсутствует</span>
									</div>
								} @else if (player.status == 3) {
									<div class="flex flex-row gap-1 items-center">
										<span class="w-2 h-2 rounded-full bg-[#EC2222]"></span>
										<span class="text-sm text-[#EC2222]">Исключен</span>
									</div>
								} @else if (player.online) {
									<div class="flex flex-row gap-1 items-center">
										<span class="w-2 h-2 rounded-full bg-[#20B56A]"></span>
										<span class="text-sm text-[#0F5733]">Онлайн</span>
									</div>
								} @else {
									<div class="flex flex-row gap-1 items-center">
										<span class="w-2 h-2 rounded-full bg-[#004D8F]"></span>
										<span class="text-sm text-[#004D8F]">Ожидание</span>
									</div>
								}

							</div>
						</div>

						@if (player.status === 3 || player.status === 2) {
							<mat-icon
								class="flex justify-center items-center text-xl w-6 h-6 cursor-pointer text-[#147FEC] font-semibold"
								(click)="restorePlayer(player)"
							>add
							</mat-icon>
						} @else {
							<mat-icon
								class="flex justify-center items-center text-xl w-6 h-6 cursor-pointer font-semibold"
								(click)="kickPlayer(player)"
							>close
							</mat-icon>
						}

					</div>

				</div>

			}

		</div>

	</div>

</div>
